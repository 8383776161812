import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/quero-ajuda',
    name: 'quero-ajuda',
    component: () => import(/* webpackChunkName: "about" */ '../views/QueroAjudaView.vue')
  },
  {
    path: '/quero-ajudar',
    name: 'quero-ajudar',
    component: () => import(/* webpackChunkName: "about" */ '../views/OferecoAjudaView.vue')
  },
  {
    path: '/lista-ajuda',
    name: 'lista-ajuda',
    component: () => import(/* webpackChunkName: "about" */ '../views/ListaAjudaView.vue')
  },
  {
    path: '/desaparecido',
    name: 'desaparecido',
    component: () => import(/* webpackChunkName: "about" */ '../views/DesaparecidoView.vue')
  },
  {
    path: '/lista-desaparecidos',
    name: 'lista-desaparecidos',
    component: () => import(/* webpackChunkName: "about" */ '../views/ListaDesaparecidosView.vue')
  },
  {
    path: '/instituicoes',
    name: 'instituicoes',
    component: () => import('../views/InstituicoesView.vue')
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('../views/MainInstituicaoView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginInstituicaoView.vue')
  },
  {
    path: '/minhas-solicitacoes',
    name: 'minhas-solicitacoes',
    component: () => import('../views/MinhasSolicitacoesView.vue')
  },
  {
    path: '/cadastrar-instituicoes',
    name: 'cadastrar-instituicoes',
    component: () => import('../views/CadastrarInstituicoesView.vue')
  },
  {
    path: '/defesa-admin-login',
    name: 'defesa-admin-login',
    component: () => import('../views/LoginDefesaView.vue')
  },
  {
    path: '/defesa-admin-validacoes',
    name: 'defesa-admin-validacoes',
    component: () => import('../views/ListaValidacoesView.vue')
  },
  
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/FAQView.vue')
  },
  
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/404View.vue') }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
