<template>
  <div class="header">
    <div class="flag">
      <img src="@/assets/barra.png" />
      <img v-if="isNotHome" class="home" @click="this.$router.push({name: 'home'})" src="@/assets/home.svg" />
    </div>
    <div class="logos">
      <img src="@/assets/logo2.png" />
      <img src="@/assets/logo_wide.png" />
    </div>
  </div>
  <router-view/>
  <a class="whats" target="_blank" href="https://wa.me/555197682272">
    <img style="height: 10vh;" src="@/assets/whats.svg" />
  </a>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

#app {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #FFFFFF;
}

input:focus{
  outline: none;
}

.flag{
  text-align: left;
  display: flex;
  justify-content: space-between;

  img {
    width: 20%;
    height: fit-content;

    @media screen and (max-width: 900px) {
      width: 30%;
    }
  }

  .home{
    width: 3vh;
    margin: 5vh;
    margin-bottom: -3vh;
    cursor: pointer;

    &:hover{
      filter: brightness(80%);
    }

    @media screen and (max-width: 900px) {
      width: 5%;
      margin: 5%;
      margin-bottom: -2%;
    }
  }
}
.whats{
  position: fixed;
  max-height: 20vh;
  right:0px;
  bottom:0vh;
  margin: 10px;
  cursor: pointer;
}
.logos {
  display: flex;
  justify-content: space-between;
  padding: 2% 5%;

  @media screen and (max-width: 900px) {
    padding: 5% 5%;
  }

  img {
    height: 14vh;
    max-width: 50%;

    @media screen and (max-width: 900px) {
      height: 10vw;
    }
  }
}

body {
  margin: 0;
  min-height: 100vh;
  background: linear-gradient(to top left, #5B1F18, rgba(255, 255, 255, 0), #535A29), linear-gradient(to top right, #634408, rgba(255, 255, 255, 0), #33460E), #4F4218;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<script>
export default {
  computed: {
    isNotHome() {
        return !(this.$route.name == 'home')
    }
  },
  methods:{
    sendWhats(){
      return true
    }
  }
}
</script>


